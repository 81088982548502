import classNames from 'classnames/bind';
import { graphql } from 'gatsby';
import React from 'react';
import Intro from '../../components/Intro/Intro';
import PrivacyTabs from '../../components/PrivacyTabs/PrivacyTabs';
import Seo from '../../components/Seo/Seo';
import * as styles from './PrivacyCenter.module.scss';

const c = classNames.bind(styles);

export const query = graphql`
  query($id: String!) {
    page: wpPage(id: { eq: $id }) {
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphSiteName
        opengraphImage {
          uri
        }
        twitterTitle
        twitterDescription
        twitterImage {
          uri
        }
      }
      translations {
        uri
        language {
          locale
        }
      }
      acf: acfPagePrivacyCenter {
        header {
          title
          popin_video
          description
          bgtype
          image {
            filesize
            height
            mime_type
            title
            url
            width
          }
          image_mobile {
            filesize
            height
            mime_type
            title
            url
            width
          }
          cover {
            filesize
            height
            mime_type
            title
            url
            width
          }
          video {
            filesize
            mime_type
            subtype
            title
            type
            url
          }
        }
        privacy_tabs {
			title
			tabs {
				title
				content
			}
        }
      }
    }
  }
`;
const PrivacyCenter = ({ data }) => {
	const { seo, translations, acf } = data.page;

	// Log the acf data to inspect its structure
	console.log(acf);

	// Check if privacy_tabs and tabs are defined and are arrays
	if (!acf.privacy_tabs || !Array.isArray(acf.privacy_tabs.tabs)) {
		return (
			<main className={c('wrapper')}>
				<Seo {...{ seo, translations }} />
				<Intro data={acf.header} />
				<p>No privacy tabs found.</p>
			</main>
		);
	}

	return (
		<main className={c('wrapper')}>
			<Seo {...{ seo, translations }} />
			<Intro data={acf.header} />
			<PrivacyTabs data={acf.privacy_tabs.tabs} />
		</main>
	);
};

export default PrivacyCenter;