import classNames from 'classnames/bind';
import React, { useEffect, useRef, useState } from 'react';
import * as styles from './PrivacyTabs.module.scss';

const c = classNames.bind(styles);

function PrivacyTabs({ data }) {
	const [activeTab, setActiveTab] = useState(0); // État pour suivre l'onglet actif
	const tabButtonsRef = useRef([]); // Référence pour stocker les boutons de tab

	// Fonction pour définir l'onglet actif basé sur le hash de l'URL
	const setTabFromUrlHash = () => {
		const hash = window.location.hash.replace('#', '').replace(/%20/g, '');
		const tabIndex = data.findIndex(tab => tab.title.replace(/\s+/g, '') === hash);
		if (tabIndex !== -1) {
			setActiveTab(tabIndex);
		}
	};

	useEffect(() => {
		// Appelle la fonction lors du montage du composant
		setTabFromUrlHash();

		// Écoute les changements du hash dans l'URL
		window.addEventListener('hashchange', setTabFromUrlHash);

		// Nettoie l'événement lors du démontage du composant
		return () => {
			window.removeEventListener('hashchange', setTabFromUrlHash);
		};
	}, [data]);

	useEffect(() => {
		// Fonction pour mettre à jour l'URL avec le nom de l'onglet actif
		const updateUrlHash = () => {
			const tabName = data[activeTab].title.replace(/\s+/g, ''); // Utilise le titre du tab comme nom dans l'URL sans espaces
			window.location.hash = tabName;
		};

		// Met à jour l'URL lorsque l'onglet actif change
		updateUrlHash();
	}, [activeTab, data]);

	const handleTabClick = (index) => {
		setActiveTab(index); // Mettre à jour l'index de l'onglet actif au clic
	};

	if (!data || !Array.isArray(data)) {
		return null; // Gérer le cas où les données ne sont pas valides
	}

	return (
		<div className={c('privacyTabs')}>
			<div className={c('tabButtons')}>
				{data.map((tab, index) => (
					<button
						key={index}
						ref={(el) => tabButtonsRef.current[index] = el} // Sauvegarde une référence au bouton
						className={c('tabButton', { active: index === activeTab })}
						onClick={() => handleTabClick(index)}
					>
						{tab.title}
					</button>
				))}
			</div>
			<div className={c('tabContents')}>
				{data.map((tab, index) => (
					<div
						key={`content-${index}`}
						id={`tab-${index}`}
						className={c('tabContent', { active: index === activeTab })}
						dangerouslySetInnerHTML={{ __html: tab.content }}
					/>
				))}
			</div>
		</div>
	);
}

export default PrivacyTabs;